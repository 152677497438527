import debug from "debug";
// localStorage.debug = "web*";
const prefix = "web";


export default {
  info: debug(`${prefix}:info`),
  error: debug(`${prefix}:error`),
  log: debug(`${prefix}:log`),
  debug: debug(`${prefix}:debug`),
};

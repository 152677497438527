import React from "react";
// import {useQuery} from "react-apollo";

import AutoSizer from "react-virtualized/dist/es/AutoSizer";
import InfiniteLoader from "react-virtualized/dist/es/InfiniteLoader";
import Table from "react-virtualized/dist/es/Table";

import throttle from "lodash.throttle";
import deepmerge from "deepmerge";
import {isPlainObject} from "is-plain-object";


import { useQuery } from "@apollo/client";
import logger from "../../utils/logger";

export default function DataTable(props) {
  const {
    query, variables,
    rowClassName, disableOddEven, onResults, onUpdateQuery,
    // ...rest
  } = props;

  const vars = Object.assign({
    pageSize: 50,
  }, variables || {});
  const results = useQuery(query, {
    variables: vars,
    fetchPolicy: "network-only",
  });
  const {loading, fetchMore} = results;
  if (results.error) {
    logger.error("Table Error", {error: results.error});
    throw results.error;
  }
  let pageInfo = {hasNextPage: true}, edges = [], total = 0;
  if (results.data && !loading) {
    const processed = onResults(results);
    pageInfo = processed.pageInfo;
    edges = processed.edges;
    total = processed.total;
  }
  const data = edges;
  if (props.filterOut) {
    data = data.filter(d => props.filterOut.indexOf(d.node.id) === -1);
  }
  let rowCount = (data || []).length;
  let tableCount = (data || []).length;
  if (rowCount < total) {
    rowCount += variables.pageSize;
  }
  let rcn = rowClassName;
  if (!disableOddEven) {
    rcn = (p) => {
      let id = "";
      if (data[p.index]) {
        if (props.getRowTestClass) {
          id = props.getRowTestClass(data[p.index], p);
        } else if (data[p.index].node) {
          id = data[p.index].node.id;
        }
      }
      return p.index % 2 === 0 ? `odd ${id}` : `even ${id}`;
    };
  }
  const loadMoreRows = createLoadMore(pageInfo, variables, fetchMore, onUpdateQuery);
  return (<AutoSizer disableHeight={!(!props.height)}>
    {({height, width}) => {
      let h = props.height || height;
      return (<InfiniteLoader
        isRowLoaded={({index}) => {
          return !!data[index];
        }}
        loadMoreRows={loadMoreRows}
        rowCount={rowCount} >
        {({onRowsRendered, registerChild}) => (<Table
          height={h} width={width}
          ref={registerChild}
          onRowsRendered={onRowsRendered}
          rowGetter={({index}) => {
            if (data[index]) {
              return data[index].node;
            }
            return {name: "Loading.."};
          }}
          rowCount={tableCount}
          rowHeight={props.rowHeight}
          headerHeight={props.headerHeight}
          rowClassName={rcn}
          className={`vnet-table ${props.className || ""}`}
          sort={props.sort}
          onHeaderClick={props.onHeaderClick}
          sortBy={props.sortBy}
          sortDirection={props.sortDirection}
          onRowClick={({event, index, rowData}) => (props.onRowClick ? props.onRowClick(event, rowData) : undefined)}
          rowStyle={props.rowStyle}
          disableHeader={props.disableHeader}
        >
          {props.children}
        </Table>)}
      </InfiniteLoader>);
    }}
  </AutoSizer>);
}

export function createLoadMore(pageInfo, variables, fetchMore, onUpdateQuery) {
  return throttle((vars) => {
    return fetchMore({
      variables: Object.assign({}, vars, variables, {
        cursor: pageInfo.endCursor,
      }),
      updateQuery(prev, result) {
        if (!onUpdateQuery) {
          const merged = deepmerge(prev, result.fetchMoreResult, {
            clone: false,
            arrayMerge: uniqueMerge,
            isMergeableObject: (o) => Array.isArray(o) || isPlainObject(o),
          });
          return merged;
        }
        return onUpdateQuery(prev, result);
      },
    });
  }, 300);
}
function uniqueMerge(source, data, options) {
  const s = source.slice();
  data.forEach((d) => {
    const i = s.findIndex((v) => v?.node?.id === d?.node?.id);
    if (i === -1) {
      s.push(d);
    } else {
      s[i] = deepmerge(s[i], d, options);
    }
  });
  return s;
}

import React, { useState } from "react";

// import {toClass} from "recompose";
import {v4 as uuid} from "uuid";
// import {useAsyncSetState} from "use-async-setstate";

import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";

import FormsyDropdown from "../formsy/dropdown";
import FormControl from "react-bootstrap/FormControl";

import Formsy from "formsy-react";
import SortTable from "./sort-table";
// import Checkbox from "../checkbox";
const comparitors = {
  "eq": "EQUALS",
  "ne": "NOT EQUALS",
  "like": "LIKE",
  "notLike": "NOT LIKE",
  "iLike": "ILIKE",
  "notILike": "NOT ILIKE",
  "true": "true",
  "false": "false",
};

export function AddFilterDialog(props) {
  const [state, setState] = useState({
    columnName: props.filterOptions && props.filterOptions.length !== 0 ? props.filterOptions[0].columnName : "",
    text: "",
    selectedOption: props.filterOptions[0] || "",
    comparitor: "eq",
  });
  return (<Formsy>
    <Modal onRequestClose={props.onRequestClose}>
      <Modal.Header>
        {"Add Filter"}
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col xs="auto">
              <FormsyDropdown label="Column"
                defaultValue={state.columnName}
                name="columnName"
                buttonProps={{outline: true, colour: "secondary"}}
                onChange={(value) => setState({
                  ...state,
                  columnName: value,
                })}
                items={props.filterOptions.map((fo) => {
                  return {name: fo.name, value: fo.columnName};
                })} />
            </Col>
            <React.Fragment>
              <Col xs="auto">
                <FormsyDropdown label="Comparitor" key={state.columnName}
                  defaultValue={state.selectedOption.comparitors[0]}
                  name="comparitor"
                  buttonProps={{outline: true, colour: "secondary"}}
                  onChange={(value) => {
                    return setState({
                      ...state,
                      comparitor: value,
                    });
                  }}
                  items={state.selectedOption.comparitors.map((c) => {
                    return {name: comparitors[c], value: c};
                  })} />
              </Col>
              <Col>
                <label>
                  {"Value"}
                </label>
                <div className="input-group field">
                  <input
                    autoComplete="off"
                    className="form-control"
                    type="text"
                    min="1" max="99999999"
                    formNoValidate
                    value={state.text}
                    name="value"
                    onChange={(e) => {
                      return setState({
                        ...state,
                        text: e.target.value,
                      });
                    }}
                  />
                </div>
              </Col>
            </React.Fragment>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col xs="auto" className="ml-auto">
              <Button
                outline colour="secondary"
                onClick={props.onRequestClose}
                type="button">
                {"Cancel"}
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  return props.onAddFilter({
                    columnName: state.columnName,
                    value: state.text,
                    comparitor: state.comparitor,
                  });
                }}
                colour="primary">
                {"Add"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>

    </Modal>
  </Formsy>);
}

export default function SearchTable(props) {
  const {variables, onSearchRequest, defaultWhere, testid, customFilter = [], ...rest} = props;
  const [search, setSearch] = useState("");

  const [showFilter, setShowFilter] = useState(!!props.showFilter);
  const [showAddFilter, setshowAddFilter] = useState(false);
  const [filters, setFilters] = useState([]);
  const [searchVars, setSearchVars] = useState(defaultWhere);
  const [customFilters, setCustomFilters] = useState(props.defaultCustomFilter || []);


  async function handleSearchChanged(e) {
    const value = e.currentTarget.value;
    setSearch(value);
    return value;
  }

  async function handleSearchSubmit(e) {
    e.preventDefault();
    if (search === "") {
      setSearchVars({
        where: props.defaultWhere,
      });
      return;
    }
    if (onSearchRequest) {
      setSearchVars(await onSearchRequest(search));
    }
  }

  function handleResetSearch(e) {
    setSearch("");
    setSearchVars({
      where: props.defaultWhere
    });
  }

  function handleToggleFilter(e) {
    e.preventDefault();
    setShowFilter(!showFilter);
  }

  function bindRemoveFilter(id) {
    return (e) => {
      e.stopPropagation();
      setFilters(filters.filter((f) => f.id !== id));
    };
  }

  function handleAddFilter(model) {
    // const filters = state.filters.concat([Object.assign({id: uuid()}, model)]);

    setFilters([...filters, Object.assign({id: uuid()}, model)]);
  }

  function addCustomFilter(model) {
    // if (model.customFunction) {
    //   model.customFunction();
    // }
    // const customFilter = customFilters.filter((f) => {
    //   return f.column === model.column;
    // });
    // if (customFilter.length === 0) {
    //   if (model.type === "single") {
    //     setCustomFilters([Object.assign({id: uuid()}, model)]);
    //     return;
    //   }
    //   setCustomFilters([...customFilters, Object.assign({id: uuid()}, model)]);
    // }
    // let cfs;
    // if (model.type === "single") {
    //   customFilters = [];
    // } else {
    //   customFilters = customFilters.filter(({column}) => {
    //     return !(column === customFilter[0].column);
    //   });
    // }
    // setCustomFilters(cus)
    // return setState({
    //   ...state,
    //   customFilters,
    //   search, showFilter, showAddFilter, filters,
    // });
  }

  let w;

  const andFilter = filters.map((f) => {
    let value;
    switch (f.type) {
      case "boolean":
        if (f.columnName === "disabled") {
          value = f.value === "true" ? false : true;
        } else {
          value = f.value === "true" ? true : false;
        }
        break;
      case "integer":
        value = parseInt(f.value);
        break;
      case "double":
      case "float":
        value = parseFloat(f.value);
        break;
      case "custom":
        return {
          [f.columnName]: f.value,
        };
      default:
        value = f.value;
    }
    return {
      [f.columnName]: {
        [f.comparitor]: value,
      },
    };
  });

  if (customFilters.length > 0) {
    let stateWhere = searchVars?.where || {};
    let objCF = {};
    let cf = customFilters;
    for (let i = 0; i < cf.length; i++) {
      if ((objCF[cf[i].column] || {}).action) {
        objCF[cf[i].column] = true;
      }
    }
    w = Object.assign({}, stateWhere, objCF);
  }
  if (filters.length > 0) {
    w = {
      ...searchVars?.where || {},
      ...w,
      and: andFilter,
    };
  } else if (searchVars?.where) {
    w = Object.assign({}, searchVars?.where, w);
  }
  let addFilterPopup;
  if (showAddFilter) {
    addFilterPopup = (<AddFilterDialog
      filterOptions={props.filterOptions}
      onRequestClose={(e) => setshowAddFilter(false)}
      onAddFilter={(model) => {
        handleAddFilter(model);
      }}
    />);
  }
  return (<React.Fragment>
    {addFilterPopup}
    <Row className="searchbar" testid={testid}>
      <Col>
        <InputGroup>
          <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Filter"}</Tooltip>}>
            <Button variant="outline-secondary" disabled={filters.length > 0}
              onClick={(e) => handleToggleFilter(e)}>
              <i className="fal fa-filter" />
            </Button>
          </OverlayTrigger>
          <FormControl name="search" onChange={(e) => handleSearchChanged(e)} value={search || ""} placeholder="Search" onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              return handleSearchSubmit(e);
            }
            return undefined;
          }} />
          <OverlayTrigger placement="bottom" overlay={<Tooltip>{"Search"}</Tooltip>}>
            <Button variant="outline-primary"
              onClick={(e) => {
                e.preventDefault();
                return handleSearchSubmit(e);
              }}>
              <i className="fal fa-search" />
            </Button>
          </OverlayTrigger>

          {(searchVars) ? (<OverlayTrigger placement="bottom" overlay={<Tooltip>{"Clear"}</Tooltip>}>
            <Button variant="outline-danger"
              onClick={(e) => handleResetSearch(e)}>
              <i className="fal fa-times" />
            </Button>
          </OverlayTrigger>) : undefined}
        </InputGroup>
      </Col>
    </Row>
    {showFilter || (filters || {}).length > 0 ? (<Row className="filterbar">
      <Col xs="auto">
        <Button className="filterbar-addbtn" onClick={(e) => {
          e.preventDefault();
          return setshowAddFilter(true);
        }} colour="link">{"+ Add Filter"}</Button>
        {customFilter.map((customFilter) => {
          return (
            <div key={customFilter.name} onClick={() => addCustomFilter({
              column: customFilter.column,
              type: customFilter.type || "default",
              action: !(customFilter.action === false),
              customFunction: customFilter.customFunction ? () => customFilter.customFunction() : () => {},
            })} className="custom-filter my-3 align-items-center">
              <Checkbox
                name="checkbox"
                checked={customFilters.filter(({column}) => {
                  return column === customFilter.column;
                }).length > 0}
                onClick={() => addCustomFilter({
                  column: customFilter.column,
                  type: customFilter.type || "default",
                  customFunction: customFilter.customFunction ? () => customFilter.customFunction() : () => {},
                })}
              />
              <div
                className={customFilters.filter(({column}) => column === customFilter.column).length > 0 ? "active" : ""}
              >{customFilter.name}</div>
            </div>
          );
        })}
        {filters.map((w) => {
          const filterOption = props.filterOptions.filter((fo) => fo.columnName === w.columnName)[0];
          const comparitorDisplay = comparitors[w.comparitor];

          return (<Col key={w.id} xs="auto">
            <Button colour="secondary" outline className="filter-badge">
              {`"${filterOption.name}" ${comparitorDisplay} '${w.value}'`}
              <span onClick={bindRemoveFilter(w.id)} style={{paddingLeft: 10}}>
                <i className="fal fa-times-circle"/>
              </span>
            </Button>
          </Col>);
        })}
      </Col>
    </Row>) : undefined}
    <Row className={"fh-element"}>
      <Col className={"fh fh-element"}>
        <div className={`fh fh-element ${props.tableClassName || ""}`}>
          <SortTable
            variables={Object.assign({}, searchVars, {where: w}, variables)}
            {...rest}>
            {props.children}
          </SortTable>
        </div>
      </Col>
    </Row>
  </React.Fragment>);
}



import React from "react";
import GenericPage from "../../components/generic-page";
import { AuthRedirect } from "../../components/auth";
import { Link } from "gatsby";
import moment from "moment-mini";
import {useTranslation} from "react-i18next";
import SortDirection from "react-virtualized/dist/es/Table/SortDirection";
import { usePermissions } from "../../logic/permissions";
import { filterGraphQLRequestFields } from "../../utils/gql";
import { getLocationListQuery, getLocationListResult, getLocationListSelectionPath } from "../../logic/location";
import PermTable from "../../components/tables/perm-table";
import { Button } from "react-bootstrap";
import "../../style/portal.scss";
// markup
export default function PortalIndex() {
  return (
    <GenericPage fh title={"Portal"}>
      <AuthRedirect to={"/login"}>
        <PortalList />
      </AuthRedirect>
    </GenericPage>
  );
}

function PortalList() {
  const {t} = useTranslation();
  const permissions = usePermissions({
    modelName: "location",
    type: "query-field",
  });
  const locationMutationPermissions = usePermissions({
    modelName: "location/%",
    type: "mutation",
  });
  if (permissions.loading || locationMutationPermissions.loading) {
    return (<React.Fragment/>);
  }
  return (<>
    <h2>{"Your Locations"}</h2>
    <div className="fh" style={{minHeight: 400}}>
      <PermTable
        permissions={permissions}
        query={filterGraphQLRequestFields(
          getLocationListQuery,
          permissions,
          getLocationListSelectionPath
        )}
        onResults={getLocationListResult}
        defaultSortBy="createdAt"
        defaultSortDirection={SortDirection.DESC}
        onSearchRequest={(value) => {
          const fields = ["address"];
          return {
            where: {
              or: fields.reduce((o, fieldName) => {
                if (permissions.hasField(fieldName)) {
                  o.push({
                    [fieldName]: {
                      iLike: `%${value}%`,
                    },
                  });
                }
                return o;
              }, []),
            },
          };
        }}
        rowHeight={40}
        headerHeight={50}
        // options={{
        //   fetchPolicy: "network-only",
        // }}
        variables={{
          pageSize: 25,
        }}
        columns={[
          {
            dataKey: "address",
            disableSort: false,
            width: 250,
            flexGrow: 1,
            headerRenderer({ dataKey, sortBy, sortDirection }) {
              return t("location.field.address");
            },
            cellRenderer({ rowData }) {
              return (
                <Link to={`/portal/locations?id=${rowData.id}`}>
                  {rowData.address}
                </Link>
              );
            },
          },
          {
            dataKey: "clientId",
            disableSort: false,
            width: 150,
            flexGrow: 1,
            headerRenderer({ dataKey, sortBy, sortDirection }) {
              return t("location.field.client");
            },
            cellRenderer({ rowData }) {
              return (
                <Link to={`https://portal.snphotography.com.au/clients/view/${rowData.clientId}`}>
                  {rowData?.client?.name}
                </Link>
              );
            },
          },
          // {
          //   dataKey: "createdAt",
          //   disableSort: false,
          //   width: 150,
          //   headerRenderer({ dataKey, sortBy, sortDirection }) {
          //     return t("location.field.createdAt");
          //   },
          //   cellRenderer({ rowData }) {
          //     return moment(rowData.createdAt).format("DD/MM/YYYY hh:mm");
          //   },
          // },
          // {
          //   dataKey: "state",
          //   disableSort: false,
          //   width: 75,
          //   headerRenderer({ dataKey, sortBy, sortDirection }) {
          //     return t("location.field.state");
          //   },
          //   cellRenderer({ rowData }) {
          //     let i;
          //     switch (rowData.state) {
          //       case "Hidden":
          //         i = <i className="colour-red fas fa-eye-slash" />;
          //         break;
          //       case "Available":
          //         i = <i className="colour-green fas fa-eye" />;
          //         break;
          //       case "ColdStorage":
          //         i = <i className="colour-blue fas fa-boxes-alt" />;
          //         break;
          //     }
          //     return <div className="text-center">{i}</div>;
          //   },
          // },
          // {
          //   dataKey: "notPublished",
          //   disableSort: true,
          //   width: 150,
          //   headerRenderer({ dataKey, sortBy, sortDirection }) {
          //     return t("location.not-published");
          //   },
          //   cellRenderer({ rowData }) {
          //     return <div className="text-center">{rowData.notPublished}</div>;
          //   },
          // },
          // {
          //   dataKey: "notReleased",
          //   disableSort: true,
          //   width: 150,
          //   headerRenderer({ dataKey, sortBy, sortDirection }) {
          //     return t("location.not-released");
          //   },
          //   cellRenderer({ rowData }) {
          //     return <div className="text-center">{rowData.notReleased}</div>;
          //   },
          // },
          // {
          //   dataKey: "errored",
          //   disableSort: true,
          //   width: 100,
          //   headerRenderer({ dataKey, sortBy, sortDirection }) {
          //     return t("location.errored");
          //   },
          //   cellRenderer({ rowData }) {
          //     return <div className="text-center">{rowData.errored}</div>;
          //   },
          // },
          // {
          //   dataKey: "total",
          //   disableSort: true,
          //   width: 100,
          //   headerRenderer({ dataKey, sortBy, sortDirection }) {
          //     return t("location.total");
          //   },
          //   cellRenderer({ rowData }) {
          //     return <div className="text-center">{rowData.total}</div>;
          //   },
          // },
          // {
          //   dataKey: "address",
          //   disableSort: true,
          //   width: 150,
          //   headerRenderer({ dataKey, sortBy, sortDirection }) {
          //     return <React.Fragment />;
          //   },
          //   cellRenderer({ rowData }) {
          //     return (
          //       <div className="table-buttons text-right">
          //         {locationMutationPermissions.hasFunction("destroy") && (
          //           <Link to={`/locations/view/${rowData.id}/delete`}>
          //             <Button variant="outline-danger">
          //               <i className="fad fa-trash-alt" />
          //             </Button>
          //           </Link>
          //         )}
          //       </div>
          //     );
          //   },
          // },
        ]}
      />
    </div>
  </>);
}

import React from "react";
import Column from "react-virtualized/dist/es/Table/Column";
import DataTable from "./search-table";


export default function PermTable(props) {
  const {columns, permissions, table = DataTable, ...rest} = props;
  const child = columns.reduce((o, c) => {
    if (permissions.hasField(c.dataKey)) {
      o.push(c);
    }
    return o;
  }, []);
  return React.createElement(table, rest, child.map((c, i) => {
    const {headerRenderer, headerClassName, ...rest} = c;
    return React.createElement(Column, {
      key: c.key || `${c.dataKey}${i}`,
      headerRenderer(headerProps) {
        const {dataKey, sortBy, sortDirection} = headerProps;
        let sortIcon = "fad fa-sort";
        if (sortBy === dataKey && !c.disableSort) {
          if (sortDirection === "ASC") {
            sortIcon = "fas fa-sort-up";
          } else {
            sortIcon = "fas fa-sort-down";
          }
        }
        return (<div className={`no-select ${headerClassName}`}>
          {headerRenderer && headerRenderer(headerProps)}
          {!c.disableSort && (<i className={`sort-icon ${sortIcon}`} />)}
        </div>);
      },
      ...rest,
    });
  }));
}

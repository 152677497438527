import React, { useEffect } from "react";
import {withFormsy} from "formsy-react";
import Dropdown from "react-bootstrap/Dropdown";

export default withFormsy(function FormsyDropdown(props) {
  const {
    onChange, defaultValue, disabled,
    value,
    showError,
    errorMessage,
    isValid,
    setValue,
    isValidValue,
    attachToForm,
    detachFromForm,
    isFormDisabled,
    runValidation,
    innerRef,
    validationError,
    validationErrors,
    errorMessages,
    hasValue,
    isFormSubmitted,
    isPristine,
    isRequired,
    resetValue,
    setValidations,
    showRequired,
    validate,
    ...rest
  } = props;
  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }, []);
  async function handleOnChange(e) {
    const value = e.currentTarget.value;
    console.log("dropdown ", e, value);
    if (onChange) {
      await onChange(value);
    }
    setValue(value);
  }
  return (<Dropdown {...rest}
    onChange={(e) => handleOnChange(e)}
    selected={value} />);
});

import React, {useState} from "react";
import SortDirection from "react-virtualized/dist/es/Table/SortDirection";
import Table from "./table";

export default function SortTable(props) {
  const {variables, rowClassName, ...rest} = props;
  const [state, setState] = useState({
    sortBy: props.defaultSortBy,
    sortDirection: props.defaultSortDirection,
  });
  let vars = Object.assign({
    pageSize: 50,
  }, variables);

  if (state.sortBy && state.sortDirection) {
    if (state.sortDirection === SortDirection.DESC) {
      vars.orderBy = `${state.sortBy}DESC`;
    } else {
      vars.orderBy = `${state.sortBy}ASC`;
    }
  }
  function onHandleSort({sortBy, sortDirection}) {
    return setState({sortBy, sortDirection});
  }
  console.log("vars", {vars, state});
  return (<Table variables={vars}
    rowClassName={rowClassName}
    sort={onHandleSort}
    sortBy={state.sortBy}
    sortDirection={state.sortDirection}
    {...rest}>
    {props.children}
  </Table>);
}
